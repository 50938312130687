export function sizeWrapper(element) {
  let wrapper = element.closest('.video-wrapper')
  if (!wrapper) {
    return
  }

  // Size the wrapper if relevant.
  const videoWidth = element.getAttribute('width')
  const videoHeight = element.getAttribute('height')
  const desiresFullWidth = !!element.closest('.video--full-width')
  const desiresFullWidthAggressive = !!element.closest(
    '.video--full-width-aggressive'
  )
  const inTheaterMode = !!element.closest('#theater-mode-container')
  const videoAspectRatio =
    videoWidth && videoHeight ? videoWidth / videoHeight : null
  let width = element.style?.width || videoWidth || element.style?.maxWidth
  if (width && /^[0-9]+$/.test(width)) {
    width += 'px'
  }

  if (inTheaterMode) {
    wrapper.style.width = ''
  } else if (
    width &&
    (!desiresFullWidthAggressive ||
      (videoAspectRatio && videoAspectRatio > 4.0)) &&
    (!desiresFullWidth || (videoAspectRatio && videoAspectRatio > 2.0))
  ) {
    wrapper.style.width = width
  }
}
